<template>
  <div class="box-service-combo-detail">
    <el-card  class="body-service-combo-detail" :body-style="{ padding: '0px' }">
      <el-row class="name-service text-center">
        {{data.name}}
      </el-row>
      <div class="d-flex justify-content-center mt-4">
        <img :src="data.image" class="img-body-common">
      </div>
      <div class="detail-card-service-combo">
        <!--                <span>{{data.name}}</span>
                        <span>code: {{data.code}}</span>-->
        <div class="bottom clearfix">
          <!--                    <div>{{data.content}}</div>-->
          <div v-html="data.detail"></div>
        </div>
        <div class="d-flex justify-content-center my-3 my-md-4 btn-submit-service-combo-detail">
          <Button :content="$route.params.name ? '戻る' : '予約'" @submit="$route.params.name ? $router.push({name: 'combo-list', query: $route.query}) : $router.push({name: 'choose-type-user'})"></Button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import apiService from "../../../core/services/api.service";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  components:{
    Button
  },
    data() {
        return {
            data: {}
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            try {
                let detail = await apiService.get('service/show/' + this.$route.params.id)
                this.data = detail.data.data
            } catch (e) {
                this.notifyError(e.message)
            }
        }
    }
}
</script>
<style scoped>
.body-service-combo-detail {
  overflow: auto;
}
.name-service {
  padding-top: 10px;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 25px;
  text-align: left;
}
</style>
